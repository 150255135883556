@charset "UTF-8";
@font-face {
  font-family: "ppFont";
  src: url('ppFont.eot');
  src: url('ppFont.eot') format("embedded-opentype"), url('ppFont.ttf') format("truetype"), url('ppFont.woff') format("woff"), url('ppFont.svg') format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=pp-icon-], [class*=" pp-icon-"], .pp-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ppFont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.title [class^=pp-icon-], .title [class*=" pp-icon-"], .title .pp-icon {
  font-size: inherit;
}
.pp-icon-lg {
  font-size: 1.7rem;
}
.pp-icon-appeal::before {
  content: "";
}
.pp-icon-arrow-left::before {
  content: "";
}
.pp-icon-arrow-right::before {
  content: "";
}
.pp-icon-arrow-down::before {
  content: "";
  transform: rotate(90deg);
  display: inline-block;
}
.pp-icon-arrow-up::before {
  content: "";
  transform: rotate(-90deg);
  display: inline-block;
}
.pp-icon-user::before {
  content: "";
}
.pp-icon-cancel {
  color: #c1c9d9;
}
.pp-icon-cancel::before {
  content: "";
}
.pp-icon-cancel:hover {
  color: #d1d7e3;
}
.pp-icon-cancel:active {
  color: #b1bbcf;
}
.pp-icon-check {
  color: #c1c9d9;
}
.pp-icon-check::before {
  content: "";
}
.pp-icon-check:hover {
  color: #d1d7e3;
}
.pp-icon-check:active {
  color: #b1bbcf;
}
.pp-icon-check_active {
  color: #00936C;
}
.pp-icon-check_active::before {
  content: "";
}
.pp-icon-check_active:hover {
  color: #00ad7f;
}
.pp-icon-check_active:active {
  color: #007a59;
}
.pp-icon-check_double {
  color: #c1c9d9;
}
.pp-icon-check_double::before {
  content: "";
}
.pp-icon-check_double:hover {
  color: #d1d7e3;
}
.pp-icon-check_double:active {
  color: #b1bbcf;
}
.pp-icon-check_double_active {
  color: #00936C;
}
.pp-icon-check_double_active::before {
  content: "";
}
.pp-icon-check_double_active:hover {
  color: #00ad7f;
}
.pp-icon-check_double_active:active {
  color: #007a59;
}
.pp-icon-checkmark-circle::before {
  content: "";
}
.pp-icon-currency::before {
  content: "";
}
.pp-icon-document::before {
  content: "";
}
.pp-icon-document-down::before {
  content: "";
}
.pp-icon-evidence::before {
  content: "";
}
.pp-icon-exit::before {
  content: "";
}
.pp-icon-radio {
  color: #c1c9d9;
}
.pp-icon-radio::before {
  content: "";
}
.pp-icon-radio:hover {
  color: #d1d7e3;
}
.pp-icon-radio:active {
  color: #b1bbcf;
}
.pp-icon-radio_active {
  color: #00936C;
}
.pp-icon-radio_active::before {
  content: "";
}
.pp-icon-radio_active:hover {
  color: #00ad7f;
}
.pp-icon-radio_active:active {
  color: #007a59;
}
.pp-icon-ticket::before {
  content: "";
}
.pp-icon-upload::before {
  content: "";
}
.pp-icon-close::before {
  content: "";
}
.pp-icon-arrow-back::before {
  content: "";
}
.pp-icon-print::before {
  content: "";
}
@font-face {
  font-family: icons-theme;
  src: url('icons-theme.eot');
  src: url('icons-theme.eot') format("embedded-opentype"), url('icons-theme.ttf') format("truetype"), url('icons-theme.woff') format("woff"), url('icons-theme.svg') format("svg");
  font-weight: 400;
  font-style: normal;
}