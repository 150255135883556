.flag {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%) scale(0.7);
  left: -1.25rem;
}

.flag-nl {
  background: url('flags_sprites.png') -10px -74px;
}

.flag-fr {
  background: url('flags_sprites.png') -10px -458px;
}

.flag-pt {
  background: url('flags_sprites.png') -10px -138px;
}

.flag-et {
  background: url('flags_sprites.png') -10px -202px;
}

.flag-pl {
  background: url('flags_sprites.png') -10px -266px;
}

.flag-de {
  background: url('flags_sprites.png') -10px -330px;
}

.flag-no {
  background: url('flags_sprites.png') -10px -394px;
}

.flag-lv {
  background: url('flags_sprites.png') -10px -10px;
}

.flag-lt {
  background: url('flags_sprites.png') -10px -522px;
}

.flag-it {
  background: url('flags_sprites.png') -10px -586px;
}

.flag-fi {
  background: url('flags_sprites.png') -10px -650px;
}

.flag-en {
  background: url('flags_sprites.png') -10px -714px;
}

.flag-dk {
  background: url('flags_sprites.png') -10px -778px;
}

.flag-sv {
  background: url('flags_sprites.png') -10px -842px;
}